.header {
  display: flex;
  align-items: center;
  padding: 15px 30px;
  border-bottom: 1px solid rgba(240, 240, 240, 0.3);
}
.header_logo {
  flex: 0 0 45px;
  display: inline-block;
  margin-right: 30px;
}
.settings_block {
  display: flex;
  align-items: center;
  //flex: 0 0 300px;
  padding-right: 20px;
}
.first_group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 126px;
}
.reminder {
  position: relative;
  width: 20px;
  margin-right: 30px;
  cursor: pointer;
  .count {
    position: absolute;
    width: 18px;
    height: 18px;
    background: #7A7AFE;
    color: #fff;
    z-index: 2;
    font-size: 11px;
    line-height: 12px;
    border-radius: 50%;
    right: -9px;
    top: -1px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.header_avatar {
  flex: 0 0 50px;
  width: 47px;
  height: 47px;
  position: relative;
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;

  &:hover .avatar_inner {
    transition: all 0.3s;
    opacity: 0.9;
    -webkit-box-shadow: 0px 0px 7px 5px rgba(255, 0, 124, 0.34);
    -moz-box-shadow: 0px 0px 7px 5px rgba(255, 0, 124, 0.34);
    box-shadow: 0px 0px 7px 5px rgba(255, 0, 124, 0.34);
  }
  .avatar_inner {
    width: 50px;
    height: 50px;
    border: 2px solid  #ff007c;
    border-radius: 50%;
    overflow: hidden;
    z-index: 1;
    transition: all 0.3s;
    img {
      width: 100%;
    }
  }
  &:before {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #7A7AFE;
    border-radius: 2px;
    z-index: 10;
  }

}
.user_sensor_active {
  position: absolute;
  right: 0;
  width: 13px;
  height: 13px;
  background: rgba(18, 166, 67, 0.8);
  border-radius: 50%;
  z-index: 10;
}
.user_sensor_inactive {
  position: absolute;
  right: 0;
  width: 13px;
  height: 13px;
  background: rgba(247, 16, 12, 0.8);
  border-radius: 50%;
  z-index: 10;
}
.header_profile_name {
  width: 140px;
  padding: 5px 0;
  margin: 0 10px;
  text-overflow: ellipsis !important;
  white-space: nowrap;
  border-bottom: 1px solid #ccc;
  overflow: hidden;
  font-weight: bold;
}
.header_profile_status {
  font-weight: normal;
}
