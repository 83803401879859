.button {
  height: 44px;
  padding: 0 20px;
  width: 100%;
  color: #fff;
  font-size: 18px;
  background: rgba(255, 0, 124, 0.8);
  border: 1px solid rgba(255, 0, 124, 0.2);
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
  font-weight: 500;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(8, 18, 53, 1) inset;
  -moz-box-shadow: 0px 0px 5px 0px rgba(8, 18, 53, 1) inset;
  box-shadow: 0px 0px 5px 0px rgba(8, 18, 53, 1) inset;
  white-space: nowrap;
  &:hover {
    background: rgba(255, 68, 156, 1);

  }
  &:active {
    background: rgba(255, 0, 124, 1);
    -webkit-box-shadow: 0px 0px 5px 0px rgba(8, 18, 53, 1) inset;
    -moz-box-shadow: 0px 0px 5px 0px rgba(8, 18, 53, 1) inset;
    box-shadow: 0px 0px 5px 0px rgba(8, 18, 53, 1) inset;
  }
}