@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');

html {
  width: 100%;
  height: 100%;
  font-size: 16px;
}

body {
  width: 100%;
  min-width: 320px;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: 'Montserrat', sans-serif !important;
  -webkit-text-size-adjust: 100%;
}
h1, h2 {
  color: #fff !important;
  /*padding-bottom: 10px;*/
}
h2 {
  display: flex;
  justify-content: space-between;
}
h3 {
  color: #fff !important;
  margin-bottom: 0 !important;
  padding-left: 65px;
  opacity: 0.8 !important;
}
h4 {
  color: #fff !important;
  margin-bottom: 0 !important;
  opacity: 0.8 !important;
  /*padding-bottom: 5px;*/
  padding-left: 5px;
}

#root {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background: linear-gradient(145deg, #081235 44%, #7A7AFE 113%);
}

a,
a:link,
a:visited,
a:hover {
  text-decoration: none;
}

.container {
  min-height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background-image: url(/src/assets/images/wave.svg);
  background-repeat: no-repeat;
  background-position: right -200px bottom 0;
  background-size: 55%;
}

.main {
  flex: 1 1 auto;
}

.wrap {
  border-radius: 3px;
  padding: 20px 30px 30px;
}

/*------------------------- Header-Nav-ANTD ------------------------------------*/
.ant-menu-overflow {
  display: block !important;
}
.ant-radio-group {
  margin-right: 5px !important;
}
.ant-menu-overflow.ant-menu.ant-menu-root.ant-menu-horizontal.ant-menu-light {
  flex: 0 1 90%;
  border-bottom: none;
  background: none !important;
}

.ant-menu-overflow-item.ant-menu-item {
  color: #fff;
  font-size: 18px;
}

.ant-menu-overflow-item.ant-menu-item.ant-menu-item-selected {
  color: #ff007c !important;
}
.ant-menu-overflow-item.ant-menu-item.ant-menu-item-selected a{
  color: #ff007c !important;
}

.ant-menu-overflow-item.ant-menu-item.ant-menu-item-active {
  color: #ff007c !important;
}
.ant-menu-overflow-item.ant-menu-item.ant-menu-item-active a{
  color: #ff007c !important;
}

.ant-menu-overflow-item.ant-menu-item.ant-menu-item-active:after {
  border-bottom: 2px solid #ff007c !important;
}

.ant-menu-overflow-item.ant-menu-item.ant-menu-item-selected:after {
  border-bottom: 2px solid #ff007c !important;
}

.ant-menu-overflow-item.ant-menu-submenu.ant-menu-submenu-horizontal {
  color: #fff;
  font-size: 18px;
}

.ant-menu-overflow-item.ant-menu-submenu.ant-menu-submenu-horizontal.ant-menu-submenu-active {
  color: #ff007c !important;
}

.ant-menu-overflow-item.ant-menu-submenu.ant-menu-submenu-horizontal.ant-menu-submenu-selected {
  color: #ff007c !important;
}

.ant-menu-overflow-item.ant-menu-submenu.ant-menu-submenu-horizontal.ant-menu-submenu-active:after {
  border-bottom: 2px solid #ff007c !important;
}

.ant-menu-overflow-item.ant-menu-submenu.ant-menu-submenu-horizontal.ant-menu-submenu-selected:after {
  border-bottom: 2px solid #ff007c !important;
}

.ant-menu-light .ant-menu-item:hover, .ant-menu-light .ant-menu-item-active, .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-light .ant-menu-submenu-active, .ant-menu-light .ant-menu-submenu-title:hover {
  color: #ff007c !important;
}

.ant-menu-submenu-popup > .ant-menu {
  background: rgba(122, 122, 254, 1) !important;
  font-weight: 500 !important;
}

.ant-menu-title-content a{
  color: #fff !important;

}

.ant-menu-title-content a:hover{
  color: #081235 !important;
}

.ant-menu-title-content a:hover{
  color: #081235 !important;
}
.ant-menu-item.ant-menu-item-active.ant-menu-item-only-child{
  color: #081235 !important;
}

.ant-menu-item.ant-menu-item-active.ant-menu-item-only-child:hover{
  color: #081235 !important;
}

.ant-menu-item.ant-menu-item-selected a {
  color: #081235 !important;
}
.ant-dropdown-link {
  color: #fff !important;
  display: flex;
  align-items: center;
}
.ant-dropdown-menu {
  margin-right: -6px !important;
  padding: 0 0 10px 0!important;
}

/*----- sub-icons-color -----*/
.ant-menu-vertical .ant-menu-item-icon {
  color: #fff !important;
}
.ant-menu-vertical .ant-menu-item.ant-menu-item-selected .ant-menu-item-icon {
  color: #081235 !important;
  width: 15px;
}
.ant-menu-vertical .ant-menu-item.ant-menu-item-active .ant-menu-item-icon {
  color: #081235 !important;
}
/*---------------------*/


.ant-menu-item.ant-menu-item-selected {
  color: #ff007c !important;
}

.ant-menu {
  color: #fff !important;
}

.ant-radio-button-wrapper {
  border: none !important;
  background: none !important;
  color: #fff !important;
}

.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  background: #7A7AFE !important;
  color: #fff !important;
  border: none !important;
}

.ant-radio-button-wrapper.ant-radio-button-wrapper-checked:before {
  background-color: #081235 !important;
}

.ant-radio-button-wrapper:not(:first-child)::before {
  background-color: transparent !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: 0 0 0 0 #7A7AFE !important;
}
.ant-menu-item-icon-active {
  color: #081235 !important;
}


/*------------------------- Login-Form-ANTD ------------------------------------*/
.ant-input-password-icon, .anticon-calendar {
  color: #fff !important;
}
.ant-input {
  background: none !important;
  color: #fff !important;
  font-size: 16px !important;
  height: 45px !important;
  border: 1px solid transparent !important;
}
.ant-input.ant-input-status-error {
  -webkit-box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.5);
  -moz-box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.5);
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.5);
}
.ant-input-affix-wrapper-status-error, .ant-select.ant-select-status-error {
  -webkit-box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.5);
  -moz-box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.5);
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.5);
}

.ant-form-item-control-input-content {
  border: 1px solid rgba(240, 240, 240, 0.3) !important;
  position: relative;
}

.ant-select-selector {
  background: transparent !important;
  border: 1px solid transparent !important;
}
.ant-select-arrow {
  color: #fff !important;
}
.ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 45px !important;
  font-size: 16px !important;
}
.ant-input-prefix {
  margin-right: 10px !important;
}

.ant-form.ant-form-horizontal.login-form {

}

.ant-select-selector {
  height: 45px !important;
}

.ant-input-affix-wrapper, .ant-select-selector, .ant-picker {
  color: #fff !important;
  background: none !important;
  border: none !important;
  padding: 0 11px !important;
}
.ant-picker {
  border: 1px solid rgba(240, 240, 240, 0.3) !important;
  cursor: pointer;
}
.ant-select-open {
  height: 45px !important;
}
.ant-select-selection-search-input {
  height: 45px !important;
}

.ant-input-affix-wrapper.ant-input-affix-wrapper-focused, .ant-select-open, .ant-picker-focused  {

  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  -moz-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-input-affix-wrapper:hover, .ant-select:hover, .ant-picker:focus  {
  border-color: #00F9CF !important;
}

.ant-input-affix-wrapper.ant-input-affix-wrapper-status-error {
  border-color: #ff4d4f !important;
}

.ant-input-affix-wrapper.ant-input-affix-wrapper-status-error {
  background: none !important;
}

.ant-picker {
  width: 100%;
  height: 47px;
  background: transparent !important;
  font-size: 15px !important;
  color: #fff !important;
}

.ant-picker-input > input {
  font-size: 15px !important;
  color: #fff !important;

}
/*------------------------- Form-ANTD-NORMALIZE------------------------------------*/

.normalize_form {
  display: flex;
}
.normalize_form .ant-form-item-control-input-content{
  border: none !important;
}
.normalize_form .ant-form-item {
  margin-bottom: 0 !important;
}



/*------------------------- Table-ANTD ------------------------------------*/
.ant-table {
  border-top: 1px solid rgba(240, 240, 240, 0.3) !important;
  border-left: 1px solid rgba(240, 240, 240, 0.3) !important;
  border-right: 1px solid rgba(240, 240, 240, 0.3) !important;
  background: rgba(8, 18, 53, 0.7) !important;
  color: white !important;
  font-size: 16px !important;
}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid rgba(240, 240, 240, 0.3) !important;
  transition: background 0.3s;
}
.ant-table-thead > tr > th {
  border-bottom: 1px solid rgba(240, 240, 240, 0.3) !important;
  background: rgba(255, 0, 124, 0.1) !important;
  color: #fff !important;
}
.ant-table-thead {
  background: rgba(8, 18, 53, 0.4) !important;
  -webkit-box-shadow: 0px 0px 1px 1px rgba(240, 240, 240, 0.2);
  -moz-box-shadow: 0px 0px 1px 1px rgba(240, 240, 240, 0.2);
  box-shadow: 0px 0px 1px 1px rgba(240, 240, 240, 0.2);
}
/*.ant-table-cell:before {*/
/*  background-color: rgba(240, 240, 240, 0.3) !important;*/
/*}*/
.ant-table-cell.ant-table-cell-row-hover {
  background: rgba(255, 255, 255, 0.1) !important;
  /*cursor: pointer;*/
}

.ant-table-cell {
  background: none !important;
}

/*------------------------- Table-control-ANTD ------------------------------------*/
.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
  padding: 10px 10px !important;
}
.ant-pagination-item {
  background-color: rgba(8, 18, 53, 0.4) !important;
  border: 1px solid rgba(240, 240, 240, 0.3) !important;
}
.ant-pagination-item:hover {
  border-color: #081235 !important;
}
.ant-pagination-item-active {
  background-color: rgba(8, 18, 53, 0.8) !important;
  border-color: rgba(8, 18, 53, 1) !important;
}
.ant-pagination-item a {
  color: #fff !important;
}
.ant-pagination-prev, .ant-pagination-next, .ant-pagination-jump-prev, .ant-pagination-jump-next {
  color: #fff !important;
}
.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
  background-color: rgba(8, 18, 53, 0.8) !important;
  border: 1px solid rgba(240, 240, 240, 0.3) !important;
}
.ant-pagination-disabled .ant-pagination-item-link, .ant-pagination-disabled:hover .ant-pagination-item-link {
  background-color: rgba(240, 240, 240, 0.6) !important;
  color: #fff !important;
}
.ant-pagination-prev button, .ant-pagination-next button {
  color: #fff !important;
}
.ant-pagination-prev button:hover, .ant-pagination-next button:hover {
  color: rgba(240, 240, 240, 0.6) !important;
}


/*------------------------- Registration-Form ------------------------------------*/

.registration_form .ant-col.ant-form-item-label {
  flex: 0 1 130px;
  text-align: right !important;
  padding-right: 7px;
}
.registration_form .ant-row {
  padding-right: 10px !important;
}

.registration_form .ant-form-item-label > label::after {
  display: none;
}

.registration_form .ant-form-item-label > label {
  color: #fff !important;
  padding-top: 13px;
}

.ant-form-item-required:before {
  color: #ff007c !important;
}
.registration_form .ant-form-item-label > label {
  white-space: normal !important;
}
/*------------------------- Collapse ------------------------------------*/
.ant-collapse {
  background-color: transparent !important;
  border: none !important;
}
.ant-collapse-content {
  background-color: transparent !important;
}
.ant-collapse-content.ant-collapse-content-active {
  background-color: transparent !important;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: #ff007c !important;
  text-align: center !important;
  padding-right: 5px !important;
  padding-left: 0 !important;
  padding-bottom: 0px !important;
  padding-top: 0 !important;
  transition: all 0.3s !important;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header:hover {
  transition: all 0.3s !important;
}

.ant-collapse-content {
  border: none !important;
}
.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 !important;
  border: none !important;
}
.ant-collapse > .ant-collapse-item {
  border: none !important;
}
.ant-collapse-content-hidden {
  display: none !important;
}
.edit_profile_collapse .ant-collapse > .ant-collapse-item > .ant-collapse-header{
  padding-left: 130px !important;
}



/*------------------------- Notice ------------------------------------*/

.ant-message-notice {
  padding-top: 95px !important;
}
.ant-message-notice-content {
  background: none !important;
  padding: 0 !important;


  /*color: #081235 !important;*/
  font-size: 18px !important;
  /*background: none !important;*/
  color: #fff !important;
}
.ant-message-custom-content.ant-message-success {
  background: rgba(18, 166, 67, 0.6) !important;
  padding: 10px 16px !important;
  border-radius: 4px;
}
.ant-message-custom-content.ant-message-error {
  background: rgba(247, 57, 57, 0.5) !important;
  /*background: rgba(255, 255, 255, 0.2) !important;*/
  padding: 10px 16px !important;
  border-radius: 4px;
}
/*------------------------- Checkbox ------------------------------------*/
.ant-checkbox-wrapper {
  line-height: 20px !important;
  margin-right: 10px !important;
  border: none !important;
  font-weight: 500 !important;
  color: #fff !important;
}
.ant-checkbox-inner::after {
  width: 12.714286px !important;
  height: 18.142857px !important;
}
.ant-checkbox-inner {
  width: 30px !important;
  height: 30px !important;
  border-radius: 50% !important;
  border: none !important;
  background: rgba(255, 255, 255, 0.4) !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: rgba(122, 122, 254, 0.8) !important;
}
.ant-checkbox-checked .ant-checkbox-inner:hover {
  background-color: #081235 !important;
}
.ant-checkbox-checked::after {
  border: none !important;
}
.ant-checkbox-checked .ant-checkbox-inner::after {
  border: 4px solid #fff !important;
  border-top: 0 !important;
  border-left: 0 !important;
  opacity: 0.7;
}
.ant-checkbox-inner::after {
  /*border-top: 0 !important;*/
  /*border-left: 0 !important;*/
}
/*------------------------- Breadcrumb ------------------------------------*/

.ant-breadcrumb {
  color: #fff !important;
  margin-bottom: 30px !important;
  opacity: 0.7;
}
.ant-breadcrumb-link, .ant-breadcrumb-separator {
  color: #fff !important;
}
.ant-breadcrumb a {
  color: #fff !important;
}
.ant-breadcrumb a:hover {
  color: #ff007c !important;
}
/*------------------------- Switch ------------------------------------*/
.ant-switch {
  background: gray !important;
}
.ant-switch-checked {
  background-color: #7A7AFE !important;
}
.ant-switch-checked:focus {
  box-shadow: none !important;
}
/*------------------------- Registration_Form ------------------------------------*/
.user_registration .ant-row {
  padding-right: 0 !important;
  display: block;
  text-align: left;
}
.user_registration .ant-form-item-label > label {
  color: #fff !important;
  padding-top: 0px !important;
}
.user_registration .ant-form-item {
  margin-bottom: 0px;
}

/*------------------------- Popconfirm ------------------------------------*/
.ant-popover {
  color: #fff !important;
}
.ant-popover-inner {
  background-color: rgba(8, 18, 53, 0.9) !important;
  color: rgba(255, 255, 255, 0.8) !important;
}
.ant-popover-message {
  color: rgba(255, 255, 255, 0.8) !important;
}
.ant-popover-arrow-content {
  background-color: transparent !important;
}
.ant-popover-arrow-content::before {
  background: rgba(8, 18, 53, 0.9) !important;
}
.ant-popover-message-title {
  line-height: 23px !important;
}
.ant-btn.ant-btn-default.ant-btn-sm {
  border-color: transparent !important;
  background: rgba(255, 255, 255, 0.4) !important;
  color: rgba(255, 255, 255, 0.8) !important;
}
.ant-btn.ant-btn-default.ant-btn-sm:hover {
  opacity: 0.8;
}
.ant-btn.ant-btn-primary.ant-btn-sm {
  background: rgba(255, 0, 124, 0.6) !important;
  border-color: transparent !important;
  color: rgba(255, 255, 255, 0.8) !important;
}
.ant-btn.ant-btn-primary.ant-btn-sm:hover {
  opacity: 0.8;
}
/*------------------------- Tooltip ------------------------------------*/
.ant-tooltip {

}
.ant-tooltip-inner {
  background: rgba(122, 122, 254, 0.9) !important;
  color: rgba(255, 255, 255, 0.8) !important;
}
.ant-tooltip-arrow-content {
  /*background: rgba(255,0,124,0.63) !important;*/
  /*background-color: rgba(8, 18, 53, 0.9) !important;*/

}
.ant-tooltip-arrow-content::before {
  background: rgba(122, 122, 254, 0.9) !important;
  /*background: rgba(8, 18, 53, 0.9) !important;*/
}

.ant-select-dropdown {
  background-color: rgba(8, 18, 53, 0.9) !important;
  color: rgba(255, 255, 255, 0.8) !important;
}
/*------------------------- Select ------------------------------------*/
.ant-select-item.ant-select-item-option {
  color: #fff !important;
}
.ant-select-item.ant-select-item-option.ant-select-item-option-active {
  color: #081235 !important;
}
.ant-select-item.ant-select-item-option.ant-select-item-option-active.ant-select-item-option-selected {
  color: #081235 !important;
}
.ant-select-item.ant-select-item-option.ant-select-item-option-active.ant-select-item-option-selected:hover {
  color: #081235 !important;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #081235 !important;
}
.ant-progress-text {
  color: #fff !important;
  opacity: 0.8 !important;
}


/*------------------------- Weather ------------------------------------*/

.recharts-responsive-container {

}
.ant-progress-circle .ant-progress-text {
  color: #fff !important;
}
/*------------------------- Tabs ------------------------------------*/

.ant-tabs-left > .ant-tabs-content-holder {
  margin-left: -1px !important;
  border-left: none !important;
  background: rgba(8, 18, 53, 0.7) !important;

}
.ant-tabs-left > .ant-tabs-content-holder .recharts-responsive-container{
  background: none !important;

}
.ant-tabs-left > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
  padding-left: 0 !important;
  border: 1px solid rgba(240, 240, 240, 0.2);
  border-radius: 0 4px 4px 4px;
  padding-right: 10px;
  -webkit-box-shadow: inset 0px 0px 8px 2px rgba(0,0,0,0.41);
  box-shadow: inset 0px 0px 8px 2px rgba(0,0,0,0.41);

}
.ant-tabs {
  color: #fff !important;
  /*border: 1px solid rgba(240, 240, 240, 0.3);*/
}

.ant-tabs-tab {
  border-top: 1px solid rgba(240, 240, 240, 0.3) !important;
  border-left: 1px solid rgba(240, 240, 240, 0.3) !important;
  border-bottom: 1px solid rgba(240, 240, 240, 0.3) !important;
  border-radius: 3px 0 0 3px;
  padding: 0px 10px !important;
  -webkit-box-shadow: inset 0px 0px 8px 2px rgba(0,0,0,0.41);
  box-shadow: inset 0px 0px 8px 2px rgba(0,0,0,0.41);

}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin: 8px 0 0 0 !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ff007c !important;


}
.ant-tabs-tab.ant-tabs-tab-active {
  transition: all 0.3s;
  -webkit-box-shadow: inset 0px 0px 8px 2px rgba(255,0,124,0.41);
  box-shadow: inset 0px 0px 8px 2px rgba(255,0,124,0.41);
}
.ant-tabs-tab.ant-tabs-tab-active svg {
  fill: #ff007c;
  transition: all 0.3s;
}
.ant-tabs-tab svg {
  transition: all 0.3s;
}
.ant-tabs-tab:hover svg {
  fill: #ff007c;
  transition: all 0.3s;
}
.ant-tabs-tab-btn:focus, .ant-tabs-tab-remove:focus, .ant-tabs-tab-btn:active, .ant-tabs-tab-remove:active {
  color: #ff007c !important;
}
.ant-tabs-tab:hover {
  color: #ff007c !important;
}
.ant-tabs-ink-bar {
  background: #ff007c !important;
}

/*------------------------- Slick Carousel ------------------------------------*/
.slick-initialized .slick-slide {
  padding: 0 5px !important;
}
/*.slick-track >div:last-child{*/
/*  border: 3px solid red !important;*/
/*}*/



/*------------------------- Auto Complete ------------------------------------*/
.autocomplete_wrap {
  display: flex;
  justify-content: flex-end;
  margin-top: -30px;
  padding-bottom: 5px;
  position: relative;
}
.autocomplete_wrap .ant-select-auto-complete {
  border: 1px solid rgba(240, 240, 240, 0.3) !important;
  height: 35px !important;
  background: rgba(8, 18, 53, 0.7) !important;
  line-height: 35px !important;
  font-size: 14px !important;
  width: 250px !important;
}
.autocomplete_wrap .ant-select-selection-search-input {
  height: 35px !important;
}
.search_icon {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 20px;
  color: #fff !important;
}
.autocomplete_wrap .ant-input-affix-wrapper, .ant-select-selector, .ant-picker {
  padding: 0 30px 0 11px !important;
}
.autocomplete_wrap .ant-select-selector {
  height: 35px !important;
}
.autocomplete_wrap .ant-select-auto-complete:hover {
  border: 1px solid rgba(240, 240, 240, 0.3) !important;
}
.autocomplete_wrap .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 35px !important;
  font-size: 14px !important;
}

/*------------------------- PLAYER ------------------------------------*/



.track-vertical {
  top: 2px;
  bottom: 2px;
  right: 2px;
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.111);
}
.thumb-vertical {
  position: relative;
  display: block;
  width: 100%;
  cursor: pointer;
  border-radius: inherit;
  background-color: rgba(255,0,124,0.4);
}
.track-horizontal {
  position: absolute;
  height: 6px;
  right: 2px;
  bottom: 2px;
  left: 2px;
  border-radius: 3px;
}
.thumb-horizontal {
  position: relative;
  display: block;
  height: 100%;
  cursor: pointer;
  border-radius: inherit;
  background-color: rgba(125, 149, 255, 0.993);
}
.view {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: scroll;
  margin-right: -15px;
  margin-bottom: -17px !important;
/*// changed from -15px  (default) to -17px (native scrollbar had a portion visible)*/
}

.leaflet-container {
  width: 100% !important;
  height: 100% !important;
}
.leaflet-popup-content {
  width: 180px !important;
  margin: 17px 7px 5px 7px;
}

.leaflet-popup-content-wrapper {
  background: rgba(8, 18, 53, 0.8) !important;
  color: #fff;
  border: none !important;
  border-radius: 4px !important;
  /*border-radius: 0 4px 4px 4px;*/
  -webkit-box-shadow: inset 0px 0px 8px 2px rgba(0,0,0,0.41);
  box-shadow: inset 0px 0px 8px 2px rgba(0,0,0,0.41);
}

.leaflet-popup-tip {
  background: rgba(8, 18, 53, 0.8) !important;
}

.leaflet-container a.leaflet-popup-close-button {
  color: #fff;
  top: -2px;
  right: 0;
}

/*------------------------- Range-picker ------------------------------------*/
.custom_range_picker {
  /*position: absolute;*/
  /*left: 10px;*/
  /*right: 10px;*/
  /*bottom: 10px;*/
}
.custom_range_picker .ant-picker {
  border: none !important;
  height: 30px !important;
}
.custom_range_picker .ant-form-item-control-input {
  min-height: 30px !important;
}
.custom_range_picker .ant-select-selector {
  height: 30px !important;
}
.custom_range_picker .ant-select-selection-search-input {
  height: 30px !important;
}
.custom_range_picker .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 30px !important;
  font-size: 16px !important;
}
.custom_range_picker .ant-select-open {
  height: 30px !important;
}
.custom_range_picker .ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 30px !important;
}
.custom_range_picker .ant-picker-input > input {
  font-size: 14px !important;
}

.custom_range_picker .anticon svg {
  fill: #fff !important;
}
.custom_range_picker .ant-picker-range .ant-picker-active-bar {
  background: #ff007c !important;
}
.custom_range_picker .ant-form-item {
  margin-bottom: 10px !important;
}
.custom_range_picker .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  font-size: 14px !important;
}
.ant-picker-panel-container {
  background: rgba(8, 18, 53, 1) !important;
  padding: 5px 10px 10px 10px !important;
  color: #cfcfcf !important;
  border: 1px solid rgba(240, 240, 240, 0.2) !important;
  border-radius: 4px;
  box-shadow: inset 0px 0px 8px 2px rgb(0 0 0 / 41%);
}

.ant-picker-header-view {
  color: #cfcfcf !important;
}
.ant-picker-date-panel .ant-picker-content th {
  color: #cfcfcf !important;
}
.ant-picker-cell-in-view {
  color: #cfcfcf !important;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
  color: #cfcfcf !important;
}
.ant-picker-range-arrow::before {
  background: rgba(255, 0, 124, 0.6) !important;
}
.ant-picker-header button {
  color: #cfcfcf !important;
}
.ant-picker-panel {
  border-bottom: 1px solid rgba(240, 240, 240, 0.2) !important;

}
.ant-picker-datetime-panel .ant-picker-time-panel {
  border-left: 1px solid rgba(240, 240, 240, 0.2) !important;
}
.ant-picker-header {
  border-bottom: 1px solid rgba(240, 240, 240, 0.2) !important;
}
.ant-picker-time-panel-column:not(:first-child) {
  border-left: 1px solid rgba(240, 240, 240, 0.2) !important;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: rgba(255, 0, 124, 0.7) !important;

}
.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner, .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
  background: rgba(255, 0, 124, 0.7) !important;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: rgba(125, 149, 255, 0.993);
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner{
  background: rgba(125, 149, 255, 0.993);
}
.ant-picker-range-arrow {
  background: rgba(8, 18, 53, 1) !important;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: rgba(255, 0, 124, 0.7) !important;
}
/*.ant-picker-cell-in-view {*/
/*  color: #000 !important;*/
/*}*/
.ant-tag.ant-tag-blue {
  background: rgba(255, 0, 124, 0.8) !important;
  border: 1px solid rgba(255, 0, 124, 0.2) !important;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
  font-weight: 500;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(8, 18, 53, 1) inset;
  -moz-box-shadow: 0px 0px 5px 0px rgba(8, 18, 53, 1) inset;
  box-shadow: 0px 0px 5px 0px rgba(8, 18, 53, 1) inset;
  white-space: nowrap;
  color: #fff !important;
}
.ant-tag.ant-tag-blue:hover {
   background: rgba(255, 68, 156, 1)!important;

 }
.ant-tag.ant-tag-blue:active {
   background: rgba(255, 0, 124, 1)!important;
   -webkit-box-shadow: 0px 0px 5px 0px rgba(8, 18, 53, 1) inset!important;
   -moz-box-shadow: 0px 0px 5px 0px rgba(8, 18, 53, 1) inset!important;
   box-shadow: 0px 0px 5px 0px rgba(8, 18, 53, 1) inset!important;
 }

.ant-popover {
  width: 500px !important;

}
.ant-popover-content {
  width: 400px !important;

}
.ant-popover-title {
  border-bottom: 1px solid rgba(240, 240, 240, 0.2) !important;
  color: #fff !important;
}
/*------------------------- Ant-select-selection-overflow ------------------------------------*/
.ant-select-tree {
  background: none !important;
  color: #fff !important;

}
.ant-tree-select .ant-select-selector {
  border: 1px solid rgba(240, 240, 240, 0.3) !important;
}
.ant-tree-select .ant-select-selector {
  height: 32px !important;
}
.ant-tree-select .ant-select-selector input {
  height: 32px !important;
}
.ant-tree-select.ant-select.ant-tree-select.ant-select-multiple.ant-select-open.ant-select-show-search {
  height: 32px !important;
}
.ant-select-selection-item {
  margin-top: 0 !important;
}
.ant-tree-select .ant-select-selection-item {
  background: #7A7AFE !important;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(8, 18, 53, 1) inset!important;
  -moz-box-shadow: 0px 0px 5px 0px rgba(8, 18, 53, 1) inset!important;
  box-shadow: 0px 0px 5px 0px rgba(8, 18, 53, 1) inset!important;
  border: 1px solid #7A7AFE !important;
}
.ant-select-tree-list-holder-inner {
  background: none !important;
}
.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
  background-color: #ff007c !important;
  border-color: #ff007c !important;
}
.ant-select-tree .ant-select-tree-node-content-wrapper:hover {
  background-color: rgba(240, 240, 240, 0.3) !important;

}
.ant-select-dropdown.ant-tree-select-dropdown.ant-select-dropdown-placement-bottomLeft {
  min-width: 400px !important;
  max-width: 400px !important;
}
.pr {
  position: relative;
}
