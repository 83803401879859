.footer {
    height: 50px;
    border-top: 1px solid rgba(240, 240, 240, 0.3);
    display: flex;
    align-items: center;
    padding: 30px 30px;
    color: #fff;
}
.footer_logo {
    height: 40px;
    margin-right: 30px;
}
.footer_logo img{
    height: 100%;
}